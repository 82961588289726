import {useApolloClient} from '@apollo/client';
import React, {useContext} from 'react';
import {logout} from '../../../contexts/auth';
import {I18nContext} from '../../../i18n';
import Link, {LinkType} from '../../system/Link';

type LinkConf = {
	tradKey: string;
	href: string;
	hide?: boolean;
};
const conf: LinkConf[] = [
	{
		tradKey: 'myBookingStatus',
		href: '/espace-client/suivi-de-commande/',
	},
	{
		tradKey: 'myProjects',
		href: '/espace-client/mes-projets',
	},
	{
		tradKey: 'myAlerts',
		href: '/espace-client/mes-alertes',
	},
	{
		tradKey: 'myBargainings',
		href: '/espace-client/mes-offres',
	},
	{
		tradKey: 'myProfile',
		href: '/espace-client/mon-profil',
	},
	{
		tradKey: 'myDocuments',
		href: '/espace-client/mes-documents',
	},
	{
		tradKey: 'myInvoices',
		href: '/espace-client/mes-factures',
	},
];

const LoggedDropdown = () => {
	const {translate} = useContext(I18nContext);
	const apolloClient = useApolloClient();
	return (
		<ul
			className="
				dropdown-menu flex-col
				top-full

				lg:bg-white
				lg:absolute
				lg:menu-shadow
				lg:px-5
				lg:py-2
				lg:hidden
				lg:w-auto

				lg:left-auto
				lg:right-0

				rounded
				whitespace-nowrap
				w-full
			"
		>
			{conf
				.filter(item => !item?.hide)
				.map(({href, tradKey}) => (
					<li className="hidden lg:block" key={tradKey}>
						<Link href={href} type={LinkType.IN_APP}>
							<a className="text-darkGray-900 font-normal py-1 block">
								{translate(tradKey)}
							</a>
						</Link>
					</li>
				))}
			<li>
				<button
					type="button"
					className="font-normal mt-7_5 pt-4 lg:pt-0 lg:py-2 px-2 lg:px-0 block border border-l-0 border-r-0 border-b-0 border-white lg:border-gray-8 w-full text-left lg:mt-2 focus:outline-none text-white lg:text-anthracite-500"
					onClick={() => logout(apolloClient)}
				>
					{translate('logout')}
				</button>
			</li>
		</ul>
	);
};

export default LoggedDropdown;
