import React, {useContext} from 'react';
import cx from 'classnames';
import {useQuery} from '@apollo/client';
import {useRouter} from 'next/compat/router';
import {useWindowScroll} from 'react-use';
import {I18nContext} from '../../../i18n';
import CalendarIcon from '../../../public/static/theme-capcar/calendar-alt-light.svg';
import CapcarIcon from '../../../public/static/theme-capcar/logo-capcar.svg';
import {QUOTATION_QUERY} from '../../../queries/quotation';
import type {Quotation} from '../../../types/generated-gateway-api-types';
import {AppointmentStatus} from '../../../types/generated-gateway-api-types';
import {Fade} from '../../system';
import Link, {LinkType} from '../../system/Link';

const LIMIT_HEADER_TOP_SCROLL_Y = 72;
const LIMIT_HEADER_STICKY_SCROLL_Y = 550;
const HEADER_STATE = {
	TOP: {
		classNameHeader: '',
		displayAppintmentBtn: false,
	},
	HIDDEN: {
		classNameHeader: 'opacity-0 -translate-y-10',
		displayAppintmentBtn: false,
	},
	STICKY: {
		classNameHeader: 'sticky opacity-1',
		displayAppintmentBtn: true,
	},
};

const hasAppointmentAvailabilitiesOrInspection = (quotation: Quotation) =>
	(quotation?.appointmentAvailabilities?.address &&
		quotation?.appointmentAvailabilities?.slots.length === 3) ||
	(quotation?.product?.inspection &&
		quotation?.product?.inspection?.status !== AppointmentStatus.CANCELED);

const useHeaderState = () => {
	const {y} = useWindowScroll();
	if (y > LIMIT_HEADER_TOP_SCROLL_Y && y < LIMIT_HEADER_STICKY_SCROLL_Y)
		return HEADER_STATE.HIDDEN;
	if (y > LIMIT_HEADER_STICKY_SCROLL_Y) return HEADER_STATE.STICKY;
	return HEADER_STATE.TOP;
};

const AppointmentHeader = () => {
	const {translate} = useContext(I18nContext);
	const headerState = useHeaderState();
	const {query} = useRouter();
	const {data: {quotation} = {}} = useQuery(QUOTATION_QUERY, {
		variables: {
			id: query?.id,
		},
		skip: !query?.id,
	});
	return (
		<nav
			className={cx(
				'top-0 left-0 py-3_4 px-2_5 z-50 transition-all ease-in duration-200 bg-white menu-shadow transform',
				headerState.classNameHeader,
			)}
		>
			<div className="flex w-full md:px-4 md:m-auto md:max-w-5xl md:flex-row justify-between flex-wrap items-center">
				<Link href="/" type={LinkType.IN_APP}>
					<a>
						<CapcarIcon className="w-auto h-10 mr-4 fill-blue-275" />
					</a>
				</Link>
				<Fade
					visible={
						!!quotation?.price && headerState.displayAppintmentBtn
					}
				>
					{hasAppointmentAvailabilitiesOrInspection(quotation) ? (
						<Link
							href={`/espace-client/mes-projets/estimations/${quotation.id}`}
							type={LinkType.IN_APP}
						>
							<a className="flex justify-center items-center text-center bg-green-550 text-label-white hover:text-label-white rounded text-sm px-4 md:px-10 py-2_5 transitionAllEaseOut transform hover:scale-105">
								{translate('pricing.rdv.show')}
							</a>
						</Link>
					) : (
						<Link
							href={`/espace-client/rendez-vous/inspection/${quotation?.id}/adresse`}
							type={LinkType.IN_APP}
							tracking={{
								category: 'Qualification',
								action: 'Prendre RDV',
								label: 'header page résultat',
							}}
						>
							<a className="flex justify-center items-center text-center bg-green-550 text-label-white hover:text-label-white rounded text-sm px-4 md:px-10 py-2_5 transitionAllEaseOut transform hover:scale-105">
								<CalendarIcon className="w-4 h-4 mr-2 inline-block text-label-white" />
								{translate('pricing.rdv.light')}
							</a>
						</Link>
					)}
				</Fade>
			</div>
		</nav>
	);
};

export default AppointmentHeader;
