import React, {useContext} from 'react';
import {Agent} from '../../../../types/generated-gateway-api-types';
import HamburgerButton from './buttons/Hamburger';
import {I18nContext} from '../../../../i18n';
import ContactUs from './buttons/ContactUs';
import Link, {LinkType} from '../../../system/Link';

export type MenuItem = {
	link?: string;
	label: string;
	type?: LinkType;
};

type OverlayMenuProps = {
	menuItems?: MenuItem[];
	buttonClass?: string;
	onOpen?: () => void;
	agent?: Agent | null;
};

type MenuItemsListProps = {
	menuItems?: MenuItem[];
	agent?: Agent | null;
	onClick?: (menuItem: MenuItem) => void;
};

const MenuItemsList: React.FC<MenuItemsListProps> = ({menuItems, agent}) => {
	const {translate} = useContext(I18nContext);

	return (
		<div className="flex flex-col h-full">
			{menuItems.map(menuItem => (
				<Link
					href={menuItem.link || '#'}
					type={menuItem.type}
					key={`menuItem-key-${menuItem.label}`}
				>
					<a
						href={menuItem.link || '#'}
						className="border-b border-gray-20 block py-4 text-left flex items-center"
					>
						<div className="w-0 h-0 border-y-8 border-l-8 border-transparent border-l-blue-275 mr-3" />
						{translate(menuItem.label)}
					</a>
				</Link>
			))}
			{agent && <ContactUs agent={agent} />}
		</div>
	);
};

const OverlayMenu: React.FC<OverlayMenuProps> = ({
	menuItems,
	buttonClass,
	agent,
}) => {
	return (
		<div>
			<input
				type="checkbox"
				id="mobile-menu-toggle"
				className="hidden touch-manipulation"
			/>
			<HamburgerButton className={buttonClass} />
			<div className="mobile-menu fixed top-0 left-0 w-screen h-screen bg-lightBlue-350 z-[51] p-4 pt-10">
				{Boolean(menuItems?.length) && (
					<MenuItemsList menuItems={menuItems} agent={agent} />
				)}
			</div>
		</div>
	);
};

export default OverlayMenu;
