"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.licensePlateInputFormatter = void 0;
const isNotNumber = char => {
  // eslint-disable-next-line no-restricted-globals
  return isNaN(char);
};
const isLetter = char => {
  return Boolean(char.length === 1 && char.match(/[A-Z]/i));
};
const shouldAddSymbol = size => [2, 6].includes(size);
const formatImmat = characters =>
/**
 * license plate : A A - # # # - A A
 * This function add '-' in the correct place:
 *  1 - when the user pass from letter <-> number
 *  2 - After typing 2 letters or 3 numbers
 *  */
characters.reduce((acc, character) => acc.concat(shouldAddSymbol(acc.length + 1) ? [character, '-'] : [character]), []).join('');
function cleanLicensePlate(licensePlate) {
  return licensePlate.replace(/[^a-z0-9]/gi, '');
}
const isWrongStr = licensePlateInputText => {
  /**
   * license plate : A A - # # # - A A
   * Orders        : 1 2   3 4 5   6 7
   * A : letters only
   * # : numbers only
   * */

  const isIncorrectCharacter = /** Letters should be in position 1, 2, 6 and 7 */
  (licensePlateInputText.length < 3 || licensePlateInputText.length >= 7) && /** Only letters from A to Z are accepted */
  !isLetter(licensePlateInputText.charAt(licensePlateInputText.length - 1));
  const isIncorrectNumber = /** Numbers should be in position 3, 4 and 5 */
  licensePlateInputText.length >= 3 && licensePlateInputText.length <= 6 && /** Only numbers (0-9) are accepted */
  isNotNumber(licensePlateInputText.charAt(licensePlateInputText.length - 1));

  /**
   * This function returns false when the input is an incorrect number or letter
   * also when a correct number/letter are in the wrong place
   * */
  return isIncorrectNumber || isIncorrectCharacter;
};
const licensePlateInputFormatter = licensePlateNumber => {
  if (!licensePlateNumber) return licensePlateNumber;
  if (isWrongStr(licensePlateNumber)) {
    return licensePlateNumber.slice(0, -1);
  }
  const onlyCharacters = cleanLicensePlate(licensePlateNumber.toUpperCase()).split('');
  if (onlyCharacters.length > 7) {
    onlyCharacters.splice(7, onlyCharacters.length - 7);
  }
  return formatImmat(onlyCharacters);
};
exports.licensePlateInputFormatter = licensePlateInputFormatter;