import React from 'react';
import Head from 'next/head';
import cx from 'classnames';
import Link, {LinkType} from '../../system/Link';

const MenuItem = ({
	href,
	label,
	title,
	active,
	as,
}: {
	href: string;
	label: React.ReactNode;
	title: string;
	active: boolean;
	as?: string;
}) => (
	<li className="mr-3">
		<Link href={href} type={LinkType.IN_APP} as={as} prefetch={false}>
			<a
				className={cx(
					'font-bold pb-4_5 mr-5 hover:text-blue-275 whitespace-nowrap block',
					{
						'text-darkGray-900': !active,
						'text-blue-275 border-b-4 border-blue-275': active,
					},
				)}
			>
				{label}
			</a>
		</Link>
		{active && (
			<Head>
				<title>{title}</title>
			</Head>
		)}
	</li>
);

export default MenuItem;
