import React, {useContext} from 'react';
import {useRouter} from 'next/compat/router';
import cx from 'classnames';
import {I18nContext} from '../../../../i18n';
import Link, {LinkType} from '../../../system/Link';
import HomeIcon from '../../../../public/static/svg/home-lg-alt-light.svg';
import SearchIcon from '../../../../public/static/svg/search-light.svg';
import WalletIcon from '../../../../public/static/svg/wallet-light.svg';
import UserIcon from '../../../../public/static/svg/user-light.svg';
import HomeIconBold from '../../../../public/static/svg/home-lg-alt-regular.svg';
import SearchIconBold from '../../../../public/static/svg/search-regular.svg';
import WalletIconBold from '../../../../public/static/svg/wallet-regular.svg';
import UserIconBold from '../../../../public/static/svg/user-regular.svg';
import HeartIconLight from '../../../../public/static/theme-capcar/heart-favorite-light.svg';
import HeartIconBold from '../../../../public/static/theme-capcar/heart-favorite-bold.svg';

const LINKS = [
	{
		label: 'home',
		link: '/',
		icon: <HomeIcon />,
		iconSelected: <HomeIconBold width="20" height="16" />,
	},
	{
		label: 'buy',
		link: '/voiture-occasion',
		activePath: '/voiture-occasion',
		icon: <SearchIcon />,
		iconSelected: <SearchIconBold width="20" height="16" />,
		tracking: {
			category: 'Source_TabBar',
			action: 'Click on TabBar icon',
			label: 'TabBar_Buyer',
		},
	},
	{
		label: 'sell',
		link: '/vendre-sa-voiture',
		activePath: '/vendre-sa-voiture',
		icon: (
			<WalletIcon
				style={{
					width: '20px',
					height: '20px',
				}}
			/>
		),
		iconSelected: (
			<WalletIconBold
				width="20"
				height="17"
				style={{
					marginBottom: '.15rem',
				}}
			/>
		),
		noPaddingWhenSelected: true,
		tracking: {
			category: 'Source_TabBar',
			action: 'Click on TabBar icon',
			label: 'TabBar_Seller',
		},
	},
	{
		label: 'favorite',
		link: '/mes-favoris',
		activePath: '/mes-favoris',
		rel: 'nofollow',
		icon: (
			<HeartIconLight
				style={{
					width: '20px',
					height: '20px',
				}}
			/>
		),
		iconSelected: (
			<HeartIconBold
				width="20"
				height="17"
				style={{
					marginBottom: '.15rem',
				}}
			/>
		),
		noPaddingWhenSelected: true,
		tracking: {
			category: 'Source_TabBar',
			action: 'Click on TabBar icon',
			label: 'TabBar_Favorite',
		},
	},
	{
		label: 'profile',
		link: '/espace-client',
		activePath: '/espace-client',
		rel: 'nofollow',
		icon: <UserIcon />,
		iconSelected: <UserIconBold width="20" height="16" />,
		tracking: {
			category: 'Source_TabBar',
			action: 'Click on TabBar icon',
			label: 'TabBar_Customer Area',
		},
	},
];

const isActive = (
	asPath: string,
	link: string,
	activePath?: string | null | undefined,
): boolean => {
	if (asPath === link) return true;
	if (activePath && asPath.includes(activePath)) return true;
	return false;
};

const MobileNavigation = ({cssContent}: {cssContent: string}) => {
	const {asPath} = useRouter();
	const {translate} = useContext(I18nContext);

	return (
		<nav role="navigation" className={cx(cssContent, 'h-19')}>
			{LINKS.map(
				({
					label,
					link,
					activePath = undefined,
					icon,
					iconSelected,
					noPaddingWhenSelected = false,
					tracking = undefined,
					rel = undefined,
				}) => {
					const isLinkActive = isActive(asPath, link, activePath);
					return (
						<Link
							key={link}
							className="flex flex-1 justify-center items-center"
							href={link}
							type={LinkType.IN_APP}
							tracking={tracking}
							prefetch={false}
						>
							<a
								rel={rel}
								className={cx(
									'flex flex-col items-center text-sm text-center',
									isLinkActive
										? 'text-blue-275 fill-blue-275 font-bold'
										: '',
								)}
							>
								{isLinkActive ? iconSelected : icon}
								<span
									className={cx(
										noPaddingWhenSelected ? 'pt-0' : 'pt-1',
									)}
								>
									{translate(label)}
								</span>
							</a>
						</Link>
					);
				},
			)}
		</nav>
	);
};

export default MobileNavigation;
