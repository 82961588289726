import React, {useContext} from 'react';
import {I18nContext} from '../../../../../i18n';
import CapcarIcon from '../../../../../public/static/theme-capcar/logo-capcar.svg';
import Link, {LinkType} from '../../../../system/Link';

const CapcarHomeIcon = () => {
	const {translate} = useContext(I18nContext);

	return (
		<Link href="/" type={LinkType.IN_APP} prefetch={false}>
			<a
				className="lg:hidden absolute top-4 left-4 justify-start text-blue-275 z-50"
				aria-label={translate('goBacKToTheHome')}
			>
				<CapcarIcon />
			</a>
		</Link>
	);
};

export default CapcarHomeIcon;
