import React, {useContext} from 'react';
import {useAsync} from 'react-use';
import {fetchDataCustomerReview} from '../../../utils/customerReview';
import {buildLinks} from '../../../utils/utilFunctions';
import {I18nContext} from '../../../i18n';
import {LinkType} from '../../system/Link';
import AvisVerifieBloc from '../AvisVerifie/AvisVerifieBloc';
import {ABOUT_US, BUY_A_CAR, HOW_DOES_IT_WORK, SELL_A_CAR} from './const';
import SocialNetwork, {LINKS_CLASSNAMES} from './SocialNetwork';

const Footer = () => {
	const {translate} = useContext(I18nContext);
	const customerReview = useAsync(() => fetchDataCustomerReview(), []);
	return (
		<footer className="bg-darkBlue-980 laptop:pb-8">
			<nav className="flex flex-wrap px-8 max-w-7xl text-white bg-darkBlue-980 m-auto justify-between flex-wrap flex-col sm:flex-row py-12">
				<div className="flex flex-col flex-1 min-w-full laptop:min-w-1/5">
					{!customerReview.loading && (
						<AvisVerifieBloc
							customerReview={customerReview.value}
						/>
					)}
				</div>

				<div className="flex flex-col font-bold flex-1 min-w-full sm:min-w-1/2 laptop:min-w-1/5">
					<span className="text-gray-20 text-xl mb-2 mt-6 laptop:mt-0">
						{translate('buyACar')}
					</span>
					{buildLinks(BUY_A_CAR)}
				</div>

				<div className="flex flex-col font-bold flex-1 min-w-full sm:min-w-1/2 laptop:min-w-1/5">
					<span className="text-gray-20 text-xl mb-2 mt-6 laptop:mt-0">
						{translate('sellACar')}
					</span>
					{buildLinks(SELL_A_CAR)}
				</div>

				<div className="flex flex-col font-bold flex-1 min-w-full sm:min-w-1/2 laptop:min-w-1/5 ">
					<span className="text-gray-20  text-xl mb-2 mt-6 laptop:mt-0">
						{translate('about')}
					</span>
					<div>
						{buildLinks([
							{
								href: `/[slug]`,
								as: `/presse`,
								label: 'press',
								type: LinkType.IN_APP,
								className: LINKS_CLASSNAMES,
							},
						])}
						<span>{` ${translate('and')} `}</span>
						{buildLinks([
							{
								href: `/[slug]`,
								as: `/partenaires-capcar`,
								label: 'partners',
								type: LinkType.IN_APP,
								className: LINKS_CLASSNAMES,
							},
						])}
					</div>
					{buildLinks(ABOUT_US)}
					<div>
						{buildLinks([
							{
								href: `/legal/[slug]`,
								as: `/legal/conditions-generales-vente`,
								label: 'cgv',
								type: LinkType.IN_APP,
								className: LINKS_CLASSNAMES,
							},
						])}
						<span>{` ${translate('and')} `}</span>
						{buildLinks([
							{
								href: `/legal/[slug]`,
								as: `/legal/conditions-generales-utilisation`,
								label: 'cgu',
								type: LinkType.IN_APP,
								className: LINKS_CLASSNAMES,
							},
						])}
					</div>
					<a
						href="#"
						className={LINKS_CLASSNAMES}
						onClick={e => {
							e.preventDefault();

							if (window.openAxeptioCookies) {
								window.openAxeptioCookies();
							}
						}}
					>
						{translate('cookie.management')}
					</a>
				</div>
				<div className="flex flex-col font-bold flex-1 min-w-full sm:min-w-1/2 laptop:min-w-1/5">
					<span className="text-gray-20 text-xl mb-2 mt-6 laptop:mt-0">
						{translate('howDoesItWork')}
					</span>
					{buildLinks(HOW_DOES_IT_WORK)}
				</div>

				<SocialNetwork />
			</nav>
		</footer>
	);
};

export default Footer;
