"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "ENERGIES_FR", {
  enumerable: true,
  get: function () {
    return _i18n.ENERGIES_FR;
  }
});
Object.defineProperty(exports, "GEARBOXES_FR", {
  enumerable: true,
  get: function () {
    return _i18n.GEARBOXES_FR;
  }
});
Object.defineProperty(exports, "TRACTION_FR", {
  enumerable: true,
  get: function () {
    return _i18n.TRACTION_FR;
  }
});
exports.capitalize = exports.brandToSlug = void 0;
Object.defineProperty(exports, "licensePlateInputFormatter", {
  enumerable: true,
  get: function () {
    return _licensePlateInputFormatter.licensePlateInputFormatter;
  }
});
exports.wordToRef = exports.slugToId = exports.searchParams = exports.productToSlug = exports.productToModelSlug = exports.productToBrandSlug = void 0;
var _slugify = _interopRequireDefault(require("slugify"));
var _licensePlateInputFormatter = require("./licensePlateInputFormatter");
var _i18n = require("./i18n");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const capitalize = word => {
  if (typeof word !== 'string') {
    return '';
  }
  return word.charAt(0).toUpperCase() + word.slice(1);
};
exports.capitalize = capitalize;
const productToSlug = ({
  brand,
  version,
  id
}) => {
  const options = {
    replacement: '_',
    lower: true
  };
  return `${(0, _slugify.default)(brand, options)}-${(0, _slugify.default)(version, options)}-${id}`;
};
exports.productToSlug = productToSlug;
const productToModelSlug = ({
  brand,
  version
}) => {
  const options = {
    replacement: '-',
    lower: true
  };
  return `${(0, _slugify.default)(brand, options)}-${(0, _slugify.default)(version, options)}`;
};
exports.productToModelSlug = productToModelSlug;
const productToBrandSlug = brand => (0, _slugify.default)(brand, {
  replacement: '-',
  lower: true
});
exports.productToBrandSlug = productToBrandSlug;
const slugToId = slug => slug.split('-').slice(-1).pop();
exports.slugToId = slugToId;
const searchParams = ({
  brands,
  versions
}) => {
  const params = new URLSearchParams('');
  if (brands) {
    brands.forEach(brand => params.append('brand', capitalize((0, _slugify.default)(brand, {
      replacement: ' ',
      lower: false
    }))));
  }
  if (versions) {
    versions.forEach(version => params.append('version', capitalize((0, _slugify.default)(version, {
      replacement: ' ',
      lower: false
    }))));
  }
  return params.toString();
};
exports.searchParams = searchParams;
const brandToSlug = brand => {
  const options = {
    replacement: '_',
    lower: true
  };
  return `${(0, _slugify.default)(brand, options)}`;
};
exports.brandToSlug = brandToSlug;
const wordToRef = word => word ? (0, _slugify.default)(word, {
  replacement: ' ',
  lower: false
}).toUpperCase() : undefined;
exports.wordToRef = wordToRef;