import React, {useContext} from 'react';
import {InternalStatus} from '../../../../../types/generated-gateway-api-types';
import {I18nContext} from '../../../../../i18n';
import LockedIcon from '../../../../../public/static/svg/locked.svg';

type Props = {
	internalStatus: InternalStatus;
};

const DoReservationSold: React.FC<Props> = ({internalStatus}) => {
	const {translate} = useContext(I18nContext);

	return (
		<div className="col-start-1 col-end-13 flex justify-center bg-gray-8 py-4 items-center">
			<LockedIcon className="fill-current mr-2" />
			<p className="text-gray-500">
				{translate(
					internalStatus === InternalStatus.SOLD_BY_KYUMP
						? 'alreadySoldProduct'
						: 'alreadyBookedProduct',
				)}
			</p>
		</div>
	);
};

export default DoReservationSold;
