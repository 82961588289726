import React, {useContext} from 'react';
import {I18nContext} from '../../../../../i18n';
import Link, {LinkType} from '../../../../system/Link';
import ArrowIcon from '../../../../../public/static/theme-capcar/arrow.svg';

const ProfileReturn = () => {
	const {translate} = useContext(I18nContext);
	return (
		<Link href="/espace-client" type={LinkType.IN_APP} prefetch={false}>
			<a className="flex py-2 align-middle absolute top-4 left-4 justify-start text-blue-275 touch-manipulation">
				<ArrowIcon className="transform rotate-90 fill-current h-3 w-3 self-center mr-1" />
				{translate('backToProfile')}
			</a>
		</Link>
	);
};

export default ProfileReturn;
