import React, {useContext} from 'react';
import cx from 'classnames';
import {InternalStatus} from '../../../../../types/generated-gateway-api-types';
import {ProductContext} from '../../../../../contexts/productContext';
import {useScrollPosition} from '../../../../../hooks';

import DoReservationForSale from './DoReservationForSale';
import DoReservationSold from './DoReservationSold';

type Props = {cssContent: string};

const DoReservation: React.FC<Props> = ({cssContent}) => {
	const scrollPosition = useScrollPosition();
	const product = useContext(ProductContext);

	if (scrollPosition === 0) {
		return null;
	}

	if (!product?.internalStatus) {
		return null;
	}

	return (
		<div
			className={cx(
				cssContent,
				'touch-manipulation bg-white py-3 px-5 flex-col',
			)}
		>
			<div className="w-full mt-2 mb-3">
				<div className="grid grid-cols-12 gap-4">
					{product?.internalStatus === InternalStatus.FOR_SALE ? (
						<DoReservationForSale product={product} />
					) : (
						<DoReservationSold
							internalStatus={product?.internalStatus}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default DoReservation;
