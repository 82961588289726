import React from 'react';
import {useRouter} from 'next/compat/router';
import cx from 'classnames';
import {LinkType} from '../../../system/Link';
import {HeaderProps} from '../types';
import ProjectOrProfileReturn from './buttons/ProjectOrProfileReturn';
import ProfileReturn from './buttons/ReturnProfile';
import MobileNavigation from './MobileNavigation';
import CarListReturn from './buttons/CarListReturn';
import Supply from './buttons/Supply';
import DoReservation from './buttons/DoReservation';
import CapcarHomeIcon from './buttons/CapcarHomeIcon';
import OverlayMenu from './OverlayMenu';

function isShowPhoneHeaders(asPath: string): boolean {
	if (asPath.startsWith('/espace-client/authentification')) return false;

	if (
		['/', '/vendre-sa-voiture', '/espace-client', '/mes-favoris'].includes(
			asPath,
		) ||
		onCatalogPage(asPath) ||
		onPublicPages(asPath)
	) {
		return true;
	}

	const showPhoneHeadersStartWith = ['/espace-client/'];

	// eslint-disable-next-line no-restricted-syntax
	for (const path of showPhoneHeadersStartWith) {
		if (asPath.startsWith(path)) return true;
	}

	return false;
}

function onReservationPage(asPath: string): boolean {
	return (
		asPath.startsWith('/voiture-occasion/') &&
		!asPath.includes('/video') &&
		!asPath.startsWith('/voiture-occasion/categorie/') &&
		!asPath.startsWith('/voiture-occasion/marque') &&
		!asPath.startsWith('/voiture-occasion/modele') &&
		!asPath.startsWith('/voiture-occasion/zone')
	);
}

function onSupplyPage(asPath: string): boolean {
	return (
		(asPath.startsWith('/vendre/') || asPath.startsWith('/blog')) &&
		!asPath.includes('/vendre/ville/') &&
		!asPath.includes('/vendre/departement/') &&
		!asPath.includes('/vendre/region/')
	);
}

function onCatalogPage(asPath: string): boolean {
	return (
		asPath === '/voiture-occasion' ||
		asPath.startsWith('/voiture-occasion?')
	);
}

function onPublicPages(asPath: string): boolean {
	return [
		onReservationPage(asPath),
		asPath.startsWith('/blog'),
		asPath.startsWith('/vendre/'),
		asPath.startsWith('/voiture-occasion/'),
		asPath.startsWith('/comment-ca-marche'),
		asPath === '/faq',
		asPath.startsWith('/credit-auto'),
		asPath.startsWith('/legal'),
		asPath.startsWith('/lexique-auto'),
		asPath.startsWith('/agents'),
		asPath.startsWith('/agences'),
		asPath.startsWith('/devenir-agent-automobile'),
		asPath.startsWith('/ouvrir-agence-automobile'),
		asPath.startsWith('/rejoindre'),
		asPath.startsWith('/reunion-information'),
		asPath.startsWith('/formulaire-agent-automobile'),
		asPath === '/contact',
	].some(val => !!val);
}

function computeTopLeftAction(asPath: string): React.ReactNode {
	if (
		asPath === '/espace-client/mes-offres' ||
		asPath === '/espace-client/mes-projets' ||
		asPath === '/espace-client/suivi-de-commande' ||
		asPath === '/espace-client/mes-alertes' ||
		asPath === '/espace-client/mon-profil'
	) {
		return <ProfileReturn />;
	}

	if (asPath.startsWith('/espace-client/mes-projets/')) {
		return <ProjectOrProfileReturn />;
	}

	if (onReservationPage(asPath)) {
		return <CarListReturn />;
	}

	if (onCatalogPage(asPath)) {
		return null;
	}

	return <CapcarHomeIcon />;
}

function computeBottomComponent(asPath: string): React.ReactNode {
	const cssContent =
		'block lg:hidden fixed z-50 justify-around bottom-0 pb-2 right-0 left-0 bg-white w-full flex align-middle border-t border-gray-17 menu-shadow';

	if (onSupplyPage(asPath)) {
		return (
			<Supply cssContent="block fixed z-50 justify-around bottom-0 pb-2 right-0 left-0 bg-white w-full flex align-middle border-t border-gray-17 menu-shadow" />
		);
	}

	if (onReservationPage(asPath)) {
		return <DoReservation cssContent={cssContent} />;
	}

	return <MobileNavigation cssContent={cssContent} />;
}

const computeHeaderStyle = (asPath: string): string => {
	if (onPublicPages(asPath))
		return 'block lg:hidden bg-white h-16 border-b-1 border-gray-17';

	if (asPath.startsWith('/espace-client/')) {
		return 'block lg:hidden h-16 bg-white mb-8 border-b-1 border-gray-17';
	}

	if (asPath.startsWith('/espace-client')) return 'block lg:hidden h-20';
	return '';
};

const MobileHeader = ({agent}: HeaderProps) => {
	const {asPath} = useRouter();

	return (
		<>
			{isShowPhoneHeaders(asPath) && (
				<div
					id="mobile-header"
					className={cx(computeHeaderStyle(asPath))}
				>
					{computeTopLeftAction(asPath)}
					<OverlayMenu
						buttonClass="absolute top-4 right-4"
						menuItems={[
							{
								link: '/voiture-occasion',
								label: 'buyMyCar',
								type: LinkType.IN_APP,
							},
							{
								link: '/vendre-sa-voiture',
								label: 'sellMyCar',
								type: LinkType.IN_APP,
							},
							{
								link: '/comment-ca-marche/processus-de-vente',
								label: 'sellerGuide',
								type: LinkType.IN_APP,
							},
							{
								link: '/comment-ca-marche/processus-achat',
								label: 'buyerGuide',
								type: LinkType.IN_APP,
							},
							{
								link: '/agents',
								label: 'findAgents',
								type: LinkType.IN_APP,
							},
							{
								link: '/agences',
								label: 'atTheAgency',
								type: LinkType.IN_APP,
							},
							{
								link: '/devenir-agent-automobile',
								label: 'becomeAnAgent',
								type: LinkType.IN_APP,
							},
							{
								link: '/ouvrir-agence-automobile',
								label: 'openAnAgency',
								type: LinkType.IN_APP,
							},
							{
								link: '/blog',
								label: 'Blog',
								type: LinkType.IN_APP,
							},
							{
								link: '/faq',
								label: 'faq',
								type: LinkType.IN_APP,
							},
						]}
						agent={agent}
					/>
				</div>
			)}

			{computeBottomComponent(asPath)}
		</>
	);
};

export default MobileHeader;
