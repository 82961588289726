import {Agent} from '../../../types/generated-gateway-api-types';

export const HeaderType = {
	DEFAULT: 'default',
	WITHOUT_PHONE: 'default_without_phone',
	APPOINTMENT: 'appointment',
	LOGO_HEADER: 'logoHeader',
	NONE: 'none',
};

export type HeaderProps = {
	header?: (typeof HeaderType)[keyof typeof HeaderType];
	agent?: Agent | null;
};
