import React, {useContext} from 'react';
import cx from 'classnames';
import LoggedDropdown from './LoggedDropdown';
import {AuthContext} from '../../../contexts/auth';
import {I18nContext} from '../../../i18n';
import UserIcon from '../../../public/static/theme-capcar/user-regular-filled.svg';
import Link, {LinkType} from '../../system/Link';

const AuthLink = () => {
	const {translate} = useContext(I18nContext);
	const {firstName, loggedIn} = useContext(AuthContext);

	if (!loggedIn)
		return (
			<Link
				href="/espace-client"
				prefetch={false}
				type={LinkType.IN_APP}
				className="w-full tablet:w-auto flex items-center mt-4 lg:mt-0"
			>
				<a
					className={cx(
						'px-2 laptop:px-3 pt-2 flex flex-col items-center text-white h-full',
					)}
					rel="nofollow"
				>
					<div className="flex">
						<UserIcon className="w-4 h-4 mr-2 self-center" />{' '}
						{translate('myAccount')}
					</div>
				</a>
			</Link>
		);
	return (
		<div className="dropdown relative pl-2 laptop:pl-0 lg:flex">
			<Link
				href="/espace-client"
				prefetch={false}
				type={LinkType.IN_APP}
				className="w-full tablet:w-auto flex items-center mt-4 lg:mt-0"
			>
				<a
					className="px-2 laptop:px-3 pt-2 flex flex-col items-center text-white h-full"
					rel="nofollow"
				>
					<div className="flex">
						<UserIcon className="w-4 h-4 mr-2 self-center" />{' '}
						{firstName?.slice(0, 13) || translate('myAccount')}
					</div>
				</a>
			</Link>
			<LoggedDropdown />
		</div>
	);
};

export default AuthLink;
