import React, {useContext} from 'react';
import {AVIS_VERIFIES_CL_LINK, AVIS_VERIFIES_LINK} from '../../../constants';
import {I18nContext} from '../../../i18n';
import type {CustomerReviewInfo} from '../../../utils/customerReview';
import {Stars} from '../../system';
import {ImageLazy} from '../../system/Image';
import Link, {LinkType} from '../../system/Link';

type Props = {
	customerReview?: CustomerReviewInfo;
};

const AvisVerifieBloc = ({customerReview}: Props) => {
	const {translate} = useContext(I18nContext);
	return (
		<Link
			href={`${AVIS_VERIFIES_LINK}/avis-clients/capcar.fr`}
			type={LinkType.EXTERNAL}
		>
			<a
				className="block bg-white rounded-xl shadow-card p-5 max-w-40"
				target="_blank"
				rel="noopener noreferrer nofollow"
			>
				<p className="w-full font-bold text-center text-orange-250 text-xl">
					{translate('outOf', {
						numerator: customerReview?.note,
						denominator: 5,
					})}
				</p>
				<div className="flex justify-center items-center my-1 h-5">
					<Stars
						score={customerReview?.note}
						fill="fill-orange-250"
						opaqueEmptyIcon
					/>
				</div>
				<p className="flex items-center text-darkBlue-980 pt-1 pb-2 text-xxs justify-center">
					{translate('reviewNumber', {
						number: customerReview?.nbPreview,
					})}
				</p>
				<div className="w-full mt-4">
					<ImageLazy
						src={`${AVIS_VERIFIES_CL_LINK}/fr/widget4/iframe/logo_170.png`}
						alt={AVIS_VERIFIES_CL_LINK}
						className="m-auto cursor-pointer"
					/>
				</div>
			</a>
		</Link>
	);
};

export default AvisVerifieBloc;
