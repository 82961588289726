import React, {useContext} from 'react';
import {useRouter} from 'next/compat/router';
import {I18nContext} from '../../../../../i18n';
import PhoneIcon from '../../../../../public/static/theme-capcar/phone-duotone.svg';
import EmailIcon from '../../../../../public/static/theme-capcar/mail.svg';
import {MODALS_NAMES} from '../../../../system/Modal/const';
import Link, {LinkType} from '../../../../system/Link';
import {usePhoneNumber} from '../../../../../hooks';
import {Product} from '../../../../../types/generated-gateway-api-types';
import {hasBargaining} from '../../../../../utils/bargaining';
import {isOpeningHours} from '../../../../../utils/opening-hours';

type DoReservationForSaleProps = {
	product: Product;
};

const DoReservationForSale: React.FC<DoReservationForSaleProps> = ({
	product,
}) => {
	const {translate} = useContext(I18nContext);
	const phoneNumber = usePhoneNumber(product?.agent?.phone);
	const {
		query: {slug},
	} = useRouter();

	const {bargainings, isMyProduct} = product;

	const buyerHasBargaining = !isMyProduct && hasBargaining(bargainings);

	return (
		<>
			{isOpeningHours() ? (
				<Link
					href={phoneNumber.href}
					type={LinkType.PHONE}
					tracking={{
						category: 'product-page-tab-bar',
						action: 'Click',
						label: 'contactez-nous',
					}}
					className="col-start-1 col-end-3"
				>
					<a className="btn rounded border border-blue-275 !px-0 !py-2 sm:!py-3">
						<PhoneIcon width={18} height={18} />
					</a>
				</Link>
			) : (
				<Link
					href="#contactForm"
					type={LinkType.ANCHOR}
					tracking={{
						category: 'product-page-tab-bar',
						action: 'Click',
						label: 'contactez-nous',
					}}
					className="col-start-1 col-end-3"
				>
					<a className="btn rounded border border-blue-275 !px-0 !py-2 sm:!py-3">
						<EmailIcon
							className="fill-blue-275"
							width={18}
							height={18}
						/>
					</a>
				</Link>
			)}
			<Link
				href={
					buyerHasBargaining
						? '/espace-client/mes-offres'
						: `#${MODALS_NAMES.CREATE_BARGAINING}`
				}
				type={LinkType.ANCHOR}
				tracking={{
					category: 'product-page-tab-bar',
					action: 'Click',
					label: 'négocier ce prix',
				}}
				replace={!buyerHasBargaining}
				className="col-start-3 col-end-8"
			>
				<a className="btn rounded border border-green-550 text-green-550 whitespace-nowrap text-sm sm:text-base !px-0 !py-2 sm:!py-3">
					{translate(
						buyerHasBargaining
							? 'seeMyBargaining'
							: 'negociateThisPrice',
					)}
				</a>
			</Link>
			<Link
				href={`/reservation/${slug}/offer-description`}
				type={LinkType.IN_APP}
				tracking={{
					category: 'product-page-tab-bar',
					action: 'Click',
					label: 'réserver',
				}}
				replace
				className="col-start-8 col-end-13"
			>
				<a
					className="btn btn-green rounded border border-green-550 text-sm sm:text-base !px-0 !py-2 sm:!py-3"
					rel="nofollow"
				>
					{translate('book')}
				</a>
			</Link>
		</>
	);
};

export default DoReservationForSale;
