import {AnimatePresence, motion} from 'framer-motion';
import React, {useEffect} from 'react';
import {useRouter} from 'next/compat/router';
import Footer from '../Footer';
import {HeaderType} from '../Header/types';
import LightFooter from '../LightFooter';
import Header from '../Header';
import AccountMenu from '../AccountMenu';
import type {LayoutProps} from './types';

export const pageVariants = {
	initial: {
		opacity: 0,
	},
	enter: {
		opacity: 1,
		transition: {
			duration: 0.5,
			ease: 'easeInOut',
		},
	},
};

const MenuType = {
	ACCOUNT: 'account',
	NONE: 'none',
};
export const FooterType = {
	FULL: 'full',
	LIGHT: 'light',
	NONE: 'none',
};

const Layout = ({
	children,
	head,
	header = HeaderType.DEFAULT,
	menu = MenuType.NONE,
	className = 'grow',
	layoutClassName = 'grow flex flex-col',
	footer = FooterType.FULL,
	agent,
	animation = true,
}: LayoutProps) => {
	const {route} = useRouter();

	useEffect(() => {
		firstRender = false;
	}, []);

	return (
		<>
			<div className="flex flex-col bg-lightBlue-350 min-h-screen">
				<Header header={header} agent={agent} />
				<AnimatePresence mode="wait">
					<motion.div
						className={layoutClassName}
						initial={
							typeof window === 'undefined' ||
							firstRender ||
							!animation
								? 'enter'
								: 'initial'
						}
						animate="enter"
						variants={pageVariants}
						key={route}
					>
						{menu === MenuType.ACCOUNT && <AccountMenu />}

						<div className={className}>{children}</div>
						{footer === FooterType.FULL && <Footer />}
						{footer === FooterType.LIGHT && <LightFooter />}
					</motion.div>
				</AnimatePresence>
			</div>
			{head}
		</>
	);
};

let firstRender = true;

export default Layout;
