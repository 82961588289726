import React from 'react';
import cx from 'classnames';
import CarLight from '../../../../../public/static/svg/car-light.svg';
import {useScrollPosition} from '../../../../../hooks';
import Link, {LinkType} from '../../../../system/Link';

type Props = {cssContent: string};

const DoReservation: React.FC<Props> = ({cssContent}) => {
	const scrollPosition = useScrollPosition();

	if (scrollPosition === 0) {
		return null;
	}

	return (
		<div
			className={cx(
				cssContent,
				'bg-white py-3 px-2 flex-col touch-manipulation',
			)}
		>
			<div className="w-full mt-2 mb-3 tablet:mt-0 tablet:mb-1">
				<div className="grid grid-cols-12 gap-2 tablet:gap-6 tablet:max-w-2xl tablet:m-auto">
					<Link
						href="/vendre-sa-voiture"
						type={LinkType.IN_APP}
						replace
						className="col-start-1 col-end-7"
					>
						<a
							className="btn btn-primary rounded border border-green-550 text-sm !px-0 !py-2 sm:!py-3"
							rel="nofollow"
						>
							<CarLight className="self-center w-6 h-4 flex fill-current mr-1 " />
							Je vends ma voiture
						</a>
					</Link>
					<Link
						href="/vendre-sa-voiture"
						type={LinkType.IN_APP}
						replace
						className="col-start-7 col-end-13"
					>
						<a
							className="btn btn-secondary rounded border border-green-550 text-sm !px-0 !py-2 sm:!py-3"
							rel="nofollow"
						>
							<CarLight className="self-center w-6 h-4 flex fill-current mr-1 " />
							J'estime ma voiture
						</a>
					</Link>
				</div>
			</div>
		</div>
	);
};

export default DoReservation;
