import React, {useContext} from 'react';
import cx from 'classnames';
import {I18nContext} from '../../../i18n';
import CapcarIcon from '../../../public/static/theme-capcar/logo-capcar.svg';
import Link, {LinkType} from '../../system/Link';
import MenuLinks from './menuItems';
import FavoriteLink from './FavoriteLink';
import AuthLink from './AuthLink';

const DesktopHeader = () => {
	const {translate} = useContext(I18nContext);
	return (
		<nav
			className={cx(
				'flex z-50 transition-all ease-in duration-200 bg-blue-275 hidden lg:block',
			)}
		>
			<div
				className={cx(
					'flex px-4 lg:px-10 w-full lg:m-auto lg:flex-row lg:justify-between flex-wrap items-center',
				)}
			>
				<Link href="/" type={LinkType.IN_APP} prefetch={false}>
					<a
						className="text-white hover:text-white py-4 lg:py-0 block"
						aria-label={translate('returnToHome')}
					>
						<CapcarIcon className="w-auto h-10 mr-4" />
					</a>
				</Link>
				<div className="flex lg:flex-row flex-col w-full lg:w-auto order-2 lg:order-1 overflow-hidden lg:overflow-visible transition-all leading-loose text-xl lg:text-base">
					<MenuLinks />
					<div className="flex py-2 flex-col lg:flex-row">
						<FavoriteLink />
						<AuthLink />
					</div>
				</div>
			</div>
		</nav>
	);
};

export default DesktopHeader;
