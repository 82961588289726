import {NextPageContext} from 'next';
import nextCookie from 'next-cookies';
import {slugToId} from '@capcar/utils-product';
import {MODALS_NAMES} from '../components/system/Modal/const';
import {withApollo} from '../contexts/apollo';
import {getAuthCookies} from '../contexts/auth';
import {
	COUNTER_OFFER_QUERY,
	PRODUCT_FOR_BARGAINING_QUERY,
} from '../queries/bargaining';
import {PROFILE_QUERY} from '../queries/customer';
import {
	Bargaining,
	BargainingStatus,
	InternalStatus,
} from '../types/generated-gateway-api-types';

const is404 = (context: NextPageContext): boolean => {
	return !context.query.slug;
};

const fetchProduct = async context => {
	const {
		query: {slug},
	} = context;
	const productId = slugToId(slug);
	const {
		data: {product},
	} = await context.apolloClient.query({
		query: PRODUCT_FOR_BARGAINING_QUERY,
		variables: {
			id: productId,
		},
	});
	return product;
};

const fetchProfile = async context => {
	const {
		data: {profile},
	} = await context.apolloClient.query({
		query: PROFILE_QUERY,
	});
	return profile;
};

export const getServerSideBargainingPageProps = async (
	context: NextPageContext,
) => {
	if (is404(context)) {
		return {
			notFound: true,
		};
	}

	const ctx = await withApollo.getApolloClient(context);
	const product = await fetchProduct(ctx);

	if (product && product.internalStatus !== InternalStatus.FOR_SALE) {
		return {
			redirect: {
				destination: `/voiture-occasion/${context.query.slug}/negociation/erreur`,
				permanent: false,
			},
		};
	}

	if (
		product?.isMyProduct ||
		product?.bargainings.find(
			bargaining =>
				bargaining.disabled === false &&
				([BargainingStatus.PENDING, BargainingStatus.ACCEPTED].includes(
					bargaining.status,
				) ||
					bargaining.counterBargaining?.status ===
						BargainingStatus.ACCEPTED),
		)
	) {
		return {
			redirect: {
				destination: `/voiture-occasion/${context.query.slug}#${MODALS_NAMES.CREATE_BARGAINING}`,
				permanent: false,
			},
		};
	}

	return {
		props: {
			product,
		},
	};
};
export const getServerSideAuthenticatedBargainingPageProps = async (
	context: NextPageContext,
) => {
	if (is404(context)) {
		return {
			notFound: true,
		};
	}

	const {token} = getAuthCookies(nextCookie(context));
	if (!token) {
		return {
			redirect: {
				destination: `/voiture-occasion/${context.query.slug}/negociation/authentification`,
				permanent: false,
			},
		};
	}

	const ctx = await withApollo.getApolloClient(context);
	const customer = await fetchProfile(ctx);

	if (!customer?.phone) {
		return {
			redirect: {
				destination: `/voiture-occasion/${context.query.slug}/negociation/authentification-final-step`,
				permanent: false,
			},
		};
	}

	return getServerSideBargainingPageProps(context);
};
export const getServerSideBargainingValidationPageProps = async (
	context: NextPageContext,
) => {
	if (is404(context)) {
		return {
			notFound: true,
		};
	}

	const ctx = await withApollo.getApolloClient(context);
	const product = await fetchProduct(ctx);

	if (product && product.internalStatus !== InternalStatus.FOR_SALE) {
		return {
			redirect: {
				destination: `/voiture-occasion/${context.query.slug}/negociation/erreur`,
				permanent: false,
			},
		};
	}

	return {
		props: {
			product,
		},
	};
};
export const getInitialErrorPageProps = async (context: NextPageContext) => {
	const ssr = typeof window === 'undefined';
	if (is404(context) && ssr) {
		context.res.statusCode = 404;
		return {
			props: {},
		};
	}
	const product = await fetchProduct(context);

	if (product) {
		return {
			product,
			productSlug: context.query.slug,
		};
	}

	if (ssr) {
		context.res.statusCode = 404;
	}

	return {};
};
export const getSSROfferPageProps = async (context: NextPageContext) => {
	if (!context?.query?.offerId) {
		return {
			notFound: true,
		};
	}

	const ctx = await withApollo.getApolloClient(context);
	const {
		query: {offerId},
	} = context;
	const {data} = await ctx.apolloClient.query({
		query: COUNTER_OFFER_QUERY,
		variables: {
			id: offerId,
		},
	});

	if (!data || !data.bargaining) {
		return {
			notFound: true,
		};
	}

	const {
		bargaining: {product, ...bargaining},
	} = data;

	return {
		props: {
			product,
			bargaining,
		},
	};
};

export const hasBargaining = (bargainings?: Bargaining[]): boolean => {
	if (!bargainings?.length) {
		return false;
	}

	return !!bargainings.find(
		bargaining =>
			(bargaining &&
				(
					[
						BargainingStatus.PENDING,
						BargainingStatus.ACCEPTED,
					] as BargainingStatus[]
				).includes(bargaining.status)) ||
			bargaining?.counterBargaining?.status === BargainingStatus.ACCEPTED,
	);
};
