import React, {useContext} from 'react';
import cx from 'classnames';
import {I18nContext} from '../../../i18n';
import FacebookIcon from '../../../public/static/svg/facebook.svg';
import LinkedInIcon from '../../../public/static/svg/linkedin.svg';
import YoutubeIcon from '../../../public/static/svg/youtube.svg';
import Link, {LinkType} from '../../system/Link';

export const LINKS_CLASSNAMES =
	'no-underline text-gray-900 font-bold hover:text-black hover:underline';

const year = new Date().getFullYear();

const LightFooter = () => {
	const {translate} = useContext(I18nContext);
	return (
		<footer className={cx('bg-white py-4 my-18 lg:mb-0')}>
			<div className="flex max-w-7xl m-auto px-4 flex-col tablet:flex-row">
				<p className="tablet:text-center font-bold text-gray-900 tablet:self-center">
					{translate('copyrightCapcar', {year})}
					<span className="hidden tablet:inline">{' • '}</span>
				</p>
				<p className="tablet:text-center tablet:text-left tablet:ml-1 font-bold text-gray-900 tablet:self-center">
					<Link
						href="/legal/[slug]"
						as="/legal/conditions-generales-utilisation"
						type={LinkType.IN_APP}
					>
						<a className={LINKS_CLASSNAMES}>{translate('legal')}</a>
					</Link>
					{' • '}
					<Link
						href="/legal/[slug]"
						as="/legal/politique-confidentialite"
						type={LinkType.IN_APP}
					>
						<a className={LINKS_CLASSNAMES}>
							{translate('confidentiality')}
						</a>
					</Link>
				</p>
				<div className="flex flex-row my-3 tablet:m-0 tablet:ml-auto tablet:self-center">
					<p className="pr-4 tablet:pr-10 pb-2">
						{translate('followUs')}
					</p>
					<ul className="flex justify-around">
						<li>
							<a
								target="_blank"
								rel="noreferrer"
								className="text-gray-900 hover:text-black"
								href="https://www.facebook.com/CapCar.fr/"
							>
								<FacebookIcon className="fill-black" />
							</a>
						</li>
						<li>
							<a
								target="_blank"
								rel="noreferrer"
								className="text-gray-900 hover:text-black"
								href="https://www.youtube.com/channel/UCgeY7cJLsiTGvib9pCxey5g"
							>
								<YoutubeIcon className="ml-4 fill-black" />
							</a>
						</li>
						<li>
							<a
								target="_blank"
								rel="noreferrer"
								className="text-gray-900 hover:text-black"
								href="https://www.linkedin.com/company/capcar/"
							>
								<LinkedInIcon className="ml-4 fill-black" />
							</a>
						</li>
					</ul>
				</div>
			</div>
		</footer>
	);
};

export default LightFooter;
