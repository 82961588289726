import React, {useContext} from 'react';
import {I18nContext} from '../../../../../i18n';
import {Agent} from '../../../../../types/generated-gateway-api-types';
import Link, {LinkType} from '../../../../system/Link';
import {usePhoneNumber} from '../../../../../hooks';
import PhoneIcon from '../../../../../public/static/theme-capcar/phone-duotone.svg';

type ContactUsProps = {
	agent?: Agent | null;
};

const ContactUs: React.FC<ContactUsProps> = ({agent}) => {
	const {translate} = useContext(I18nContext);
	const phoneNumber = usePhoneNumber(agent?.phone);
	return (
		<Link
			href={phoneNumber.href}
			type={LinkType.PHONE}
			className="w-full flex items-center text-center"
			prefetch={false}
		>
			<a className="pt-8 w-full font-bold text-blue-275 hover:text-blue-275 flex flex-col text-center justify-center py-2 h-full">
				{translate(agent?.phone ? 'yourAgentAnswersYou' : 'goCallUs')}
				<div className="mt-4">
					<PhoneIcon width={45} height={45} className="inline" />
					<span className="ml-3 text-lg">{phoneNumber.number}</span>
				</div>
			</a>
		</Link>
	);
};

export default ContactUs;
