import React from 'react';
import CapcarIcon from '../../../public/static/svg/capcar.svg';
import Link, {LinkType} from '../../system/Link';

const LogoHeader = () => (
	<nav className="relative z-20 bg-blue-275 flex justify-center p-3 w-full">
		<Link href="/" type={LinkType.IN_APP}>
			<a className="mr-auto">
				<CapcarIcon className="w-auto h-10 fill-white" />
			</a>
		</Link>
	</nav>
);

export default LogoHeader;
