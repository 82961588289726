import React, {useContext} from 'react';
import {useRouter} from 'next/compat/router';
import {I18nContext} from '../../../i18n';
import MenuItem from './MenuItem';
import {AccountMenuContext, useRefreshAccount} from './context';

const AccountMenu = () => {
	const {pathname} = useRouter();
	const {translate} = useContext(I18nContext);
	const {myProjectsConf, myBookingConf, myAlertConf, myBargainingConf} =
		useContext(AccountMenuContext);
	useRefreshAccount();

	return (
		<div className="bg-white w-full mb-6 hidden tablet:block">
			<div className="w-full tablet:max-w-7xl tablet:m-auto px-4 pt-5 flex justify-between overflow-x-auto items-start">
				<ul className="flex">
					<MenuItem
						label={myBookingConf.label}
						href={myBookingConf.href}
						title={translate('myProjects')}
						active={[
							'/espace-client/suivi-de-commande',
							'/espace-client/suivi-de-commande/[productId]',
							'/espace-client/suivi-de-commande/historique',
						].includes(pathname)}
					/>
					<MenuItem
						label={myProjectsConf.label}
						title={translate('myBookingsStatus')}
						href={myProjectsConf.href}
						active={[
							'/espace-client/mes-projets',
							'/espace-client/mes-projets/[productId]',
							'/espace-client/mes-projets/estimations/[quotationId]',
						].includes(pathname)}
					/>
					<MenuItem
						label={myAlertConf.label}
						title={translate('myAlerts')}
						href={myAlertConf.href}
						active={['/espace-client/mes-alertes'].includes(
							pathname,
						)}
					/>
					<MenuItem
						label={myBargainingConf.label}
						href={myBargainingConf.href}
						title={translate('myBargainings')}
						active={pathname === '/espace-client/mes-offres'}
					/>
					<MenuItem
						label={translate('myDocuments')}
						title={translate('myDocuments')}
						href="/espace-client/mes-documents"
						active={
							pathname ===
							'/espace-client/mes-documents/[[...productId]]'
						}
					/>
					<MenuItem
						label={translate('invoicesAndCertificates.short_title')}
						title={translate('invoicesAndCertificates.title')}
						href="/espace-client/mes-factures"
						active={pathname === '/espace-client/mes-factures'}
					/>
					<MenuItem
						label={translate('myProfile')}
						title={translate('myProfile')}
						href="/espace-client/mon-profil"
						active={pathname === '/espace-client/mon-profil'}
					/>
				</ul>
			</div>
		</div>
	);
};

export default AccountMenu;
