import React, {useState, useEffect} from 'react';
import {useAnchorState} from '../../../hooks';
import {MODALS_NAMES} from '../../system/Modal/const';
import AppointmentHeader from './AppointmentHeader';
import DesktopHeader from './DesktopHeader';
import MobileHeader from './mobile/MobileHeader';
import LogoHeader from './LogoHeader';
import type {HeaderProps} from './types';
import {HeaderType} from './types';

const Header = ({header, agent}: HeaderProps) => {
	const [anchor] = useAnchorState();

	const [firstRender, setFirstRender] = useState(true);

	useEffect(() => {
		setFirstRender(false);
	}, []);

	if (header === HeaderType.APPOINTMENT) {
		return <AppointmentHeader />;
	}

	if (header === HeaderType.LOGO_HEADER) {
		return <LogoHeader />;
	}

	if (header === HeaderType.NONE) {
		return null;
	}

	if (!firstRender && anchor === MODALS_NAMES.PRODUCT_FILTERS) {
		return null;
	}

	return (
		<>
			<DesktopHeader />
			<MobileHeader agent={agent} />
		</>
	);
};

export default Header;
