import React, {useState, useContext, useEffect} from 'react';
import {useLocalStorage} from 'react-use';
import {I18nContext} from '../../../../../i18n';
import Link, {LinkType} from '../../../../system/Link';
import ArrowIcon from '../../../../../public/static/theme-capcar/arrow.svg';

const CarListReturn = () => {
	const {translate} = useContext(I18nContext);
	const [returnUrl, setReturnUrl] = useState('/voiture-occasion');
	const [carSearch] = useLocalStorage('carSearch', '');
	useEffect(() => {
		if (carSearch) setReturnUrl(`/voiture-occasion${carSearch}`);
	}, []);
	return (
		<Link href={returnUrl} type={LinkType.IN_APP} prefetch={false}>
			<a className="flex py-2 align-middle absolute top-4 left-4 justify-start font-bold text-blue-275 touch-manipulation">
				<ArrowIcon className="transform rotate-90 fill-current h-3 w-3 self-center mr-1" />
				{translate('back')}
			</a>
		</Link>
	);
};

export default CarListReturn;
