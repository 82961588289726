import * as React from 'react';
import {getTranslate} from '../i18n';
import Link, {LinkType} from '../components/system/Link';

export type LinkDefinitionType = {
	href: string;
	as?: string | null | undefined;
	label: string;
	optionalLabel?: string;
	className?: string;
	type: LinkType;
	blank?: boolean;
};
export const buildLinks = (links: Array<LinkDefinitionType>) =>
	links.map<React.ReactNode>(
		({
			href,
			as,
			label,
			optionalLabel = '',
			className,
			type,
			blank,
		}: LinkDefinitionType) => (
			<Link
				href={href}
				as={as}
				key={`${label}${optionalLabel}`}
				type={type}
				prefetch={false}
			>
				<a
					className={className}
					target={blank ? '_blank' : null}
					rel={
						type === LinkType.EXTERNAL
							? 'noreferrer noopener'
							: null
					}
				>
					{getTranslate('fr')(label)} {optionalLabel}
				</a>
			</Link>
		),
	);
