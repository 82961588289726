import React from 'react';
import cx from 'classnames';

type Props = {
	className?: string;
};

const HamburgerButton: React.FC<Props> = ({className}) => {
	return (
		<label
			className={cx(
				className,
				'hamburger-lines z-[52] active:border-none focus:border-none active:outline-none focus:outline-none touch-manipulation',
			)}
			htmlFor="mobile-menu-toggle"
		>
			<span className="line line1" />
			<span className="line line2" />
			<span className="line line3" />
		</label>
	);
};

export default HamburgerButton;
