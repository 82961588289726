export const MODALS_NAMES = Object.freeze({
	ALERT: 'alert',
	BOOKING_MODAL: 'booking_modal',
	CONTACT_MODAL: 'contact_modal',
	CONTACT_FORM_MODAL: 'contact_form_modal',
	CANCEL_AVAILABILITIES: 'cancel_availabilities',
	CANCEL_BOOKING: 'cancelBooking',
	CANCEL_INSPECTION: 'cancel_inspection',
	CHANGE_WARRANTY: 'changeWarranty',
	CREATE_BARGAINING: 'new_bargaining',
	ESTIMATE: 'estimate',
	FINANCING: 'getFinancing',
	GRAY_CARD_PICTURE: 'grayCard_picture',
	PREPARE_INSPECTION: 'prepare_inspection',
	PRODUCT_INFORMATION: 'product_information',
	PRODUCT_PRICE_UPDATE: 'product_price_update',
	PRODUCT_OPTIONS: 'product_options',
	QUOTATION_EXPLANATION: 'quotation_explanation',
	QUOTATION_MORE_DETAIL: 'quotation_more_detail',
	NOT_MY_CAR: 'not_my_car',
	SIMULATOR: 'simulator',
	SIMULATOR_LEGAL: 'simulator_legal',
	FAQ_QUALIF: 'faq_qualif_modal',
	PRODUCT_FILTERS: 'productFilters',
	ALLIANZ_BUYER: 'allianz_buyer',
	ALLIANZ_SELLER: 'allianz_seller',
	PRODUCT_STATS_AD_LINKS_HELP: 'product_stats_ad_links_help',
	PRODUCT_STATS_AD_VIEWS_HELP: 'product_stats_ad_views_help',
	PRODUCT_STATS_RECOMMENDATION_HELP:
		'product_stats_recommendation_views_help',
	PRODUCT_STATS_BARGAININGS_VIEWS_HELP:
		'product_stats_bargainings_views_help',
	FEEDBACK: 'capcar_feedback',
	NB_DOORS: 'example_doors',
});
