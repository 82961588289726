import React, {useContext} from 'react';
import {I18nContext} from '../../../i18n';
import FacebookIcon from '../../../public/static/svg/facebook.svg';
import LinkedInIcon from '../../../public/static/svg/linkedin.svg';
import YoutubeIcon from '../../../public/static/svg/youtube.svg';
import CapcarIcon from '../../../public/static/theme-capcar/logo-capcar.svg';
import Link, {LinkType} from '../../system/Link';

export const LINKS_CLASSNAMES =
	'no-underline text-white hover:text-white hover:underline lg:text-md leading-relaxed';

const year = new Date().getFullYear();

const SocialNetwork = () => {
	const {translate} = useContext(I18nContext);
	return (
		<div className="w-full flex pt-6 mt-6 border-0 border-t-2 flex-col tablet:flex-row items-center md:pb-14">
			<CapcarIcon className="w-auto h-10 mr-4" />

			<p className="text-center">
				{translate('copyrightCapcar', {year})}
				{' • '}
				<Link
					href="/legal/[slug]"
					as="/legal/conditions-generales-utilisation"
					type={LinkType.IN_APP}
					prefetch={false}
				>
					<a className={LINKS_CLASSNAMES}>{translate('legal')}</a>
				</Link>
				{' • '}
				<Link
					href="/legal/[slug]"
					as="/legal/politique-confidentialite"
					type={LinkType.IN_APP}
					prefetch={false}
				>
					<a className={LINKS_CLASSNAMES}>
						{translate('confidentiality')}
					</a>
				</Link>
			</p>
			<div className="flex flex-col tablet:flex-row mx-auto my-16 tablet:my-0 tablet:ml-auto tablet:mr-0">
				<p className="tablet:pr-10 pb-2">{translate('followUs')}</p>
				<div className="flex justify-around">
					<a
						target="_blank"
						rel="noreferrer"
						className="text-white hover:text-white"
						href="https://www.facebook.com/CapCar.fr/"
						aria-label="Facebook"
					>
						<FacebookIcon className="fill-current" />
					</a>
					<a
						target="_blank"
						rel="noreferrer"
						className="text-white hover:text-white"
						href="https://www.youtube.com/channel/UCgeY7cJLsiTGvib9pCxey5g"
						aria-label="Youtube"
					>
						<YoutubeIcon className="ml-2 fill-current" />
					</a>
					<a
						target="_blank"
						rel="noreferrer"
						className="text-white hover:text-white"
						href="https://www.linkedin.com/company/capcar/"
						aria-label="LinkedIn"
					>
						<LinkedInIcon className="ml-3 fill-current" />
					</a>
				</div>
			</div>
		</div>
	);
};

export default SocialNetwork;
