import {LinkType} from '../../system/Link';
import {LINKS_CLASSNAMES} from './SocialNetwork';

export const ABOUT_US = [
	{
		href: `/qui-sommes-nous`,
		label: 'whoAreWe',
		type: LinkType.IN_APP,
		className: LINKS_CLASSNAMES,
	},
	{
		href: '/[slug]',
		as: '/contact',
		label: 'contactUs',
		type: LinkType.IN_APP,
		className: LINKS_CLASSNAMES,
	},
	{
		href: '/devenir-agent-automobile',
		label: 'becomeAnAgent',
		type: LinkType.IN_APP,
		className: LINKS_CLASSNAMES,
	},
	{
		href: '/ouvrir-agence-automobile',
		label: 'openAnAgency',
		type: LinkType.IN_APP,
		className: LINKS_CLASSNAMES,
	},
];
export const HOW_DOES_IT_WORK = [
	{
		href: `/comment-ca-marche/prix`,
		label: 'capcarPrice',
		type: LinkType.IN_APP,
		className: LINKS_CLASSNAMES,
	},
	{
		href: `/comment-ca-marche/la-certification-dinspection`,
		label: `capcarCertification`,
		type: LinkType.IN_APP,
		className: LINKS_CLASSNAMES,
	},
	{
		href: `/comment-ca-marche/assurance-auto`,
		label: `carInsurance`,
		type: LinkType.IN_APP,
		className: LINKS_CLASSNAMES,
	},
	{
		href: '/comment-ca-marche/les-agents',
		label: 'agents',
		type: LinkType.IN_APP,
		className: LINKS_CLASSNAMES,
	},
	{
		href: '/agences',
		label: 'agencies',
		type: LinkType.IN_APP,
		className: LINKS_CLASSNAMES,
	},
	{
		href: `/lexique-auto`,
		label: 'automotiveLexicon',
		type: LinkType.IN_APP,
		className: LINKS_CLASSNAMES,
	},
	{
		href: `/faq`,
		label: 'faq',
		type: LinkType.IN_APP,
		className: LINKS_CLASSNAMES,
	},
];
export const BUY_A_CAR = [
	{
		href: `/comment-ca-marche/processus-achat`,
		label: 'buyerGuide',
		type: LinkType.IN_APP,
		className: LINKS_CLASSNAMES,
	},
	{
		href: '/voiture-occasion/categorie/citadines',
		label: 'usedCityDwellers',
		type: LinkType.IN_APP,
		className: LINKS_CLASSNAMES,
	},
	{
		href: '/voiture-occasion/categorie/berlines',
		label: 'usedSedan',
		type: LinkType.IN_APP,
		className: LINKS_CLASSNAMES,
	},
	{
		href: '/voiture-occasion/categorie/4x4-et-suv',
		label: 'used4x4AndSubs',
		type: LinkType.IN_APP,
		className: LINKS_CLASSNAMES,
	},
	{
		href: '/voiture-occasion/categorie/cabriolets',
		label: 'usedConvertibles',
		type: LinkType.IN_APP,
		className: LINKS_CLASSNAMES,
	},
	{
		href: `/comment-ca-marche/assurance-auto`,
		label: 'insurance',
		type: LinkType.IN_APP,
		className: LINKS_CLASSNAMES,
	},
	{
		href: `/comment-ca-marche/nos-garanties`,
		label: 'warranty',
		type: LinkType.IN_APP,
		className: LINKS_CLASSNAMES,
	},
];
export const SELL_A_CAR = [
	{
		href: `/comment-ca-marche/processus-de-vente`,
		label: 'sellerGuide',
		type: LinkType.IN_APP,
		className: LINKS_CLASSNAMES,
	},
	{
		href: `/vendre-sa-voiture`,
		label: 'sellMyCar',
		type: LinkType.IN_APP,
		className: LINKS_CLASSNAMES,
	},
	{
		href: `/agents`,
		label: 'agentsLinkCard.findAgent',
		type: LinkType.IN_APP,
		className: LINKS_CLASSNAMES,
	},
	{
		href: `/vendre/departement/paris`,
		label: 'sellParis',
		type: LinkType.IN_APP,
		className: LINKS_CLASSNAMES,
	},
	{
		href: `/vendre/ville/marseille`,
		label: 'sellMarseille',
		type: LinkType.IN_APP,
		className: LINKS_CLASSNAMES,
	},
	{
		href: `/vendre/ville/lyon`,
		label: 'sellLyon',
		type: LinkType.IN_APP,
		className: LINKS_CLASSNAMES,
	},
	{
		href: `/vendre/ville/bordeaux`,
		label: 'sellBordeaux',
		type: LinkType.IN_APP,
		className: LINKS_CLASSNAMES,
	},
];
