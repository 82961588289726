export const isOpeningHours = () => {
	const date = new Date();
	const hours = date.getHours();
	const day = date.getDay();

	if (day === 0) {
		return false;
	}

	if (day === 6 && hours >= 18) {
		return false;
	}

	if (hours >= 19) {
		return false;
	}

	if (hours < 9) {
		return false;
	}

	return true;
};
