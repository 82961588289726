import cx from 'classnames';
import {useRouter} from 'next/compat/router';
import React from 'react';
import {getTranslate} from '../../../i18n';
import Link, {LinkType} from '../../system/Link';

const menuItems: Array<{
	label: string;
	link: string;
	as?: string | null | undefined;
	activePath?: string[] | null | undefined;
	type: LinkType;
	target?: string;
}> = [
	{
		label: 'buyMyCar',
		link: '/voiture-occasion',
		type: LinkType.IN_APP,
		activePath: ['/voiture-occasion'],
	},
	{
		label: 'sellMyCar',
		link: '/vendre-sa-voiture',
		activePath: ['/vendre-sa-voiture', '/vendre'],
		type: LinkType.IN_APP,
	},
	{
		label: 'howDoesItWork',
		link: `/comment-ca-marche/processus-achat`,
		activePath: ['/comment-ca-marche'],
		type: LinkType.IN_APP,
	},
	{
		label: 'ourAgents',
		link: '/agents',
		activePath: ['/agents', '/agences'],
		type: LinkType.IN_APP,
	},
	{
		link: '/devenir-agent-automobile',
		activePath: [
			'/formulaire-agent-automobile',
			'/reunion-information',
			'/inscription-formation-agent-automobile',
			'/inscription-reunion-information',
		],
		label: 'becomeAnAgent',
		type: LinkType.IN_APP,
	},
	{
		label: 'blog',
		link: '/blog',
		activePath: ['/blog'],
		type: LinkType.IN_APP,
	},
];

const styleForActiveLink = (
	asPath: string,
	link: string,
	activePath?: string[] | null | undefined,
) => {
	const isActive =
		asPath === link ||
		(activePath &&
			activePath.filter(path => asPath.startsWith(path)).length > 0);

	return isActive
		? 'font-bold !border-opacity-40'
		: 'hover:!border-opacity-10';
};

const MenuLinks = () => {
	const {asPath} = useRouter();

	return (
		<>
			{menuItems.map(({label, link, type, activePath, target}) => (
				<Link
					key={link}
					href={link}
					type={type}
					prefetch={type === LinkType.IN_APP ? false : undefined}
				>
					<a
						className={cx(
							'px-2 lg:py-4 lg:px-2 laptop:px-4 xl:px-7 h-full transition transition-all duration-500 text-white border-white border-l-4 lg:border-l-0 lg:border-b-4 border-opacity-0',
							styleForActiveLink(asPath, link, activePath),
						)}
						target={target ?? '_self'}
					>
						{getTranslate('fr')(label)}
					</a>
				</Link>
			))}
		</>
	);
};

export default MenuLinks;
