"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TRACTION_FR = exports.GEARBOXES_FR = exports.ENERGIES_FR = void 0;
const ENERGIES_FR = exports.ENERGIES_FR = {
  PETROL: 'Essence',
  DIESEL: 'Diesel',
  ELECTRIC: 'Électrique',
  HYBRID_DIESEL: 'Hybride diesel',
  HYBRID_GAS: 'Hybride gaz',
  HYBRID_PETROL: 'Hybride essence',
  HYBRID_PLUG_IN: 'Hybride rechargeable',
  DUAL_FUEL_PETROL_BIOETHANOL: 'Bicarburation essence bioéthanol',
  DUAL_FUEL_PETROL_GNV: 'Bicarburation essence GNV',
  DUAL_FUEL_PETROL_GPL: 'Bicarburation essence GPL',
  BIOFUEL: 'Biocarburant',
  HYDROGEN: 'Hydrogène',
  OTHER: 'Autre'
};
const GEARBOXES_FR = exports.GEARBOXES_FR = {
  MANUAL: 'Manuelle',
  AUTOMATIC: 'Automatique',
  ROBOTIC: 'Robotisée'
};
const TRACTION_FR = exports.TRACTION_FR = {
  '2WD': '2 roues motrices',
  '4WD': '4 roues motrices'
};