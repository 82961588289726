import React, {useContext} from 'react';
import {useQuery, gql} from '@apollo/client';
import {I18nContext} from '../../../../../i18n';
import Link, {LinkType} from '../../../../system/Link';
import ArrowIcon from '../../../../../public/static/theme-capcar/arrow.svg';
import ReturnProfile from './ReturnProfile';

export const MY_QUOTATIONS_AND_PRODUCTS = gql`
	query myProducts {
		profile {
			myQuotations {
				id
			}
			myProducts {
				id
			}
		}
	}
`;

const ProjectOrProfileReturn = () => {
	const {data} = useQuery(MY_QUOTATIONS_AND_PRODUCTS);
	const {translate} = useContext(I18nContext);
	const quotationsNumber = data?.profile?.myQuotations?.length || 0;
	const productsNumber = data?.profile?.myProducts?.length || 0;
	const projectsNumber = quotationsNumber + productsNumber;
	if (projectsNumber <= 1) return <ReturnProfile />;
	return (
		<Link
			href="/espace-client/mes-projets"
			type={LinkType.IN_APP}
			prefetch={false}
		>
			<a className="flex py-2 align-middle absolute top-4 left-4 justify-start text-blue-275 touch-manipulation">
				<ArrowIcon className="transform rotate-90 fill-current h-3 w-3 self-center mr-1" />
				{translate('backToMyProjects')}
			</a>
		</Link>
	);
};

export default ProjectOrProfileReturn;
